
main.notion figure.notion-asset-wrapper {
  max-width: 150%;
}

main.notion figure.notion-asset-wrapper div {
  padding-bottom: 50px !important;
}

main.notion figure.notion-asset-wrapper div img {
  border-radius: 0.75rem;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
